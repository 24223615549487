import { RawLineDict } from "../types.d";

// conversion table from planweb WBS to gjennomføringsfase for gitt tilpasningsprosjekt.
// Leveranser utgjør neste nivå, aktiviteter under det igjen.
export const planwebWBSTo75xxPrecursor: any = {
    "2.2": "7501",
    "3.2": "7502",
    "4.3": "7503",
    "5.2": "7504",
    "6.3": "7505",
    "7.3": "7506",
    "8.3": "7507",
    "9.2": "7509",
    "10.2": "7510",
    "11.3": "7513",
    "12.2": "7514",
    "13.3": "7516",
    "14.2": "7591"
}

export default function postProcessRawData(lineDictInput: RawLineDict, order: string[]): RawLineDict {

    let lineDict: RawLineDict = {}
    let curr75xx = ""
    let curr75xxPrefix = ""
    let currLvl2 = 0
    let currLPrefix = ""


    const u6WBS = Object.values(lineDictInput)
        .find(line => line.desc === 'Medisinsk teknisk utstyr U6 PNSD')
        ?.WBS || '2.6' // current default

    const u7WBS = Object.values(lineDictInput)
        .find(line => line.desc === 'Tilpasningsprosjektene U7')
        ?.WBS || '2.8' // current default

    let planwebWBSTo75xx: any = {}
    Object.keys(planwebWBSTo75xxPrecursor).forEach(precursorKey => {
        planwebWBSTo75xx[`${u7WBS}.${precursorKey}`] = planwebWBSTo75xxPrecursor[precursorKey]
    })


    order.forEach(seq => {

        const line = lineDictInput[seq]
        let next = {
            ...line,
            lvl1: "",
            lvl2: 0,
            isLvl2: false,
            lane: 1,
            u: 0
        }

        if (line.WBS.startsWith(`${u6WBS}.`)) {
            next.lvl1 = "U6" // TODO: Deprecate
            next.u = 6
        }
        else if (line.WBS.startsWith(`${u7WBS}.`)) {
            next.u = 7
        }

        // Er det enn curr75xx? Hvis ikke, se om denne er det
        if (!curr75xx) {
            if (planwebWBSTo75xx[line.WBS]) {
                curr75xx = planwebWBSTo75xx[line.WBS]
                curr75xxPrefix = line.WBS
                currLPrefix = ""
                currLvl2 = 0
                // next.lvl1 = curr75xx
            }
        }

        // Er det en L? Hvis ikke, se om denne er det
        else if (currLvl2 === 0) {
            if (line.seq.startsWith("p")) {
                currLvl2 = 1
                next.lvl1 = curr75xx
                next.lvl2 = currLvl2
                currLPrefix = line.WBS
                next.isLvl2 = true
            }
        }

        // Ellers - curr75xx og currL er satt
        else {

            // hvis WBS stemmer med currLPrefix - typ som aktivitet
            if (line.WBS.startsWith(currLPrefix)) {
                next.lvl1 = curr75xx
                next.lvl2 = currLvl2
                next.isLvl2 = false

            }

            // Hvis ikke WBS stemmer med currLPrefix, men med 75xx sjekk om ny L eller skip
            else if (!line.WBS.startsWith(currLPrefix) && line.WBS.startsWith(curr75xxPrefix)) {
                if (line.seq.startsWith("p")) {
                    currLvl2 = currLvl2 + 1
                    currLPrefix = line.WBS
                    next.lvl1 = curr75xx
                    next.lvl2 = currLvl2
                    next.isLvl2 = true
                }
            }

            // Hvis ikke WBS stemmer med currLPrefix eller med 75xx, unset begge.
            else if (!line.WBS.startsWith(currLPrefix) && !line.WBS.startsWith(curr75xxPrefix)) {
                if (planwebWBSTo75xx[line.WBS]) {
                    curr75xx = planwebWBSTo75xx[line.WBS]
                    curr75xxPrefix = line.WBS
                    currLPrefix = ""
                    currLvl2 = 0
                    // next.lvl1 = curr75xx
                }
                else {
                    curr75xx = ""
                    curr75xxPrefix = ""
                    currLPrefix = ""
                    currLvl2 = 0
                }

            }

        }

        lineDict[seq] = next


    })

    return lineDict

}