import { Box } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { TimeSegment } from "../../hooks/useLegendTimelineData";
import { selectShowWeeks } from "../../slice";


export default function VerticalWeekIndicator(props: { segment: TimeSegment, index: number }) {

    const { start, width } = props.segment
    const showWeeks = useSelector(selectShowWeeks)

    const color = props.index % 2 === 0 ? undefined: 'blackAlpha.500'

    return showWeeks ? (
        <React.Fragment>
            <Box
                id="long-line"
                position="absolute"
                left={`${start - 1}px`}
                width={`${width}px`}
                height={"100%"}
                bg={color}
                zIndex={-3}
                opacity={.075}
            // opacity={.20}
            />
        </React.Fragment>
    ) : null
}