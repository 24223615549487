import * as d3 from 'd3';
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { leftBar } from '../Layout';
import { selectScaleX, selectTimelineEnd, selectTimelineStart, selectViewportWidth } from '../slice';

export interface TimeSegment {
    label: string;
    start: number;
    end: Date;
    width: number;
}

export default function useLegendTimelineData(): {
    yearSegments: TimeSegment[],
    monthSegments: TimeSegment[],
    weekSegments: TimeSegment[],
} {

    const [yearSegments, setYearSegments] = useState<TimeSegment[]>([]);
    const [monthSegments, setMonthSegments] = useState<TimeSegment[]>([]);
    const [weekSegments, setWeekSegments] = useState<TimeSegment[]>([]);
    const startRaw = useSelector(selectTimelineStart)
    const endRaw = useSelector(selectTimelineEnd)
    const viewWidth = useSelector(selectViewportWidth)
    const scaleX = useSelector(selectScaleX)


    useEffect(() => {

        if (startRaw && endRaw && viewWidth > 0) {

            const timelineStart = DateTime.local(...startRaw)
            const timelineEnd = DateTime.local(...endRaw)

            const timeScale = d3
                .scaleTime()
                .domain([timelineStart.toJSDate(), timelineEnd.toJSDate()])
                .range([leftBar, viewWidth])

            const start = DateTime.local(...startRaw);
            const end = DateTime.local(...endRaw);

            const years = d3.timeYear.range(start.startOf('year').toJSDate(), end.toJSDate());
            const yearData = years.map((d, i) => {
                const startDate = i === 0 ? start.toJSDate() : d
                const endDate = i + 1 === years.length ? end.toJSDate() : DateTime.fromJSDate(d).endOf('year').toJSDate()
                return {
                    label: DateTime.fromJSDate(d).toFormat('yyyy'),
                    start: timeScale(startDate),
                    end: endDate,
                    width: timeScale(endDate) - timeScale(startDate),
                }
            });
            setYearSegments(yearData);

            // Generate month segments
            const months = d3.timeMonths(start.toJSDate(), end.toJSDate());
            const monthData = months.map((d) => ({
                label: DateTime.fromJSDate(d).toFormat('MMM'),
                start: timeScale(d),
                end: DateTime.fromJSDate(d).endOf('month').toJSDate(),
                width: timeScale(DateTime.fromJSDate(d).endOf('month').toJSDate()) - timeScale(d),
            }));
            setMonthSegments(monthData);

            // Generate week segments
            const weeks = d3.timeWeeks(start.toJSDate(), end.toJSDate());
            const weekData = weeks.map((d) => {
                const weekStart = DateTime.fromJSDate(d).startOf('week').toJSDate()
                const weekEnd = DateTime.fromJSDate(d).endOf('week').toJSDate()
                return {
                    label: `${DateTime.fromJSDate(d).weekNumber}`,
                    start: timeScale(weekStart),
                    end: weekEnd,
                    width: timeScale(weekEnd) - timeScale(weekStart),
                }
            });
            setWeekSegments(weekData);

        }

    }, [viewWidth, startRaw, endRaw, scaleX]);

    return {
        yearSegments,
        monthSegments,
        weekSegments,
    }
}
