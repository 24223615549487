import { Button, ButtonGroup, FormControl, FormLabel, Stack, Switch } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { footerBar } from "../../Layout";
import { selectLookAhead, selectShowDates, selectShowDeps, selectShowProgress, selectShowWeeks, selectTid, selectView, selectViewBygg, selectViewportWidth, selectVisible, setLookAhead, setShowDates, setShowDeps, setShowProgress, setShowWeeks, setTid, setView, setViewBygg, setVisible } from "../../slice";
import { Lvl1, View, byggstruktur, lvl1 } from "../../types.d";


export const footerBarHeight = 40

export default function TmpFooterBar() {

    const dispatch = useDispatch()
    const visible = useSelector(selectVisible)
    const lookAhead = useSelector(selectLookAhead)
    const showDates = useSelector(selectShowDates)
    const showDeps = useSelector(selectShowDeps)
    const showWeeks = useSelector(selectShowWeeks)
    const showProg = useSelector(selectShowProgress)
    const view = useSelector(selectView)
    const tid = useSelector(selectTid)
    const viewBygg = useSelector(selectViewBygg)
    const viewportWidth = useSelector(selectViewportWidth)
    const showU7buttons = ['extended'].includes(view)
    const showByggButtons = ['byggplan'].includes(view)
    const isMultiline = true // viewportWidth < 1900 && (showU7buttons || showByggButtons)

    const toggleSingleVisible = (id: Lvl1) => {
        dispatch(setVisible([id]))
        // if (visible.includes(id)) {
        //     dispatch(setVisible(visible.filter(e => e !== id)))
        // }
        // else {
        //     dispatch(setVisible([...visible, id]))
        // }
    }

    const toggleView = (viewId: View) => {
        if (view === viewId) {
            dispatch(setView('standard'))
        }
        else {
            dispatch(setView(viewId))
        }
    }

    const toggleViewBygg = (id: string) => {
        dispatch(setViewBygg(id))
        // if (viewBygg === id) {
        //     dispatch(setViewBygg(''))
        // }
        // else {
        //     dispatch(setViewBygg(id))
        // }
    }

    const makeAllVisible = () => {
        dispatch(setVisible([...lvl1]))
    }

    const makeAllHidden = () => {
        dispatch(setVisible([]))
    }

    const nextVisible = () => {
        if (visible.length === 1) {
            const index = lvl1.indexOf(visible[0])
            const nextIndex = index === lvl1.length - 1 ? 0 : index + 1
            const nextU7 = lvl1[nextIndex]
            dispatch(setVisible([nextU7]))
        }
    }

    const prevVisible = () => {
        if (visible.length === 1) {
            const index = lvl1.indexOf(visible[0])
            const previndex = index === 0 ? lvl1.length - 1 : index - 1
            const nextU7 = lvl1[previndex]
            dispatch(setVisible([nextU7]))
        }
    }


    return (
        <Stack
            width={`${100}%`}
            height={`${footerBar * (isMultiline ? 2 : 1)}px`}
            bg="gray.200"
            direction={isMultiline ? "column" : "row"}
            px="20px"
            justify={isMultiline ? "space-around" : "space-between"}
            align={isMultiline ? "flex-start" : "center"}
            spacing="0"
        >
            <Stack
                direction="row"
                alignItems={"center"}>

                <Stack
                    direction="row"
                    alignItems='center'
                    spacing="0">
                    <FormLabel mb="0" fontSize="xs">
                        Visning:
                    </FormLabel>
                    <ButtonGroup
                        size='sm'
                        isAttached
                        variant='outline'
                        pr="2"
                    >
                        <Button
                            borderColor="gray.500"
                            _hover={{ borderColor: 'gray.400' }}
                            size="xs"
                            bg={view === 'satplan-chron' ? 'yellow.50' : undefined}
                            onClick={() => toggleView('satplan-chron')}
                        >SAT-plan (IKT)</Button>
                        <Button
                            borderColor="gray.500"
                            _hover={{ borderColor: 'gray.400' }}
                            size="xs"
                            bg={view === 'extended' ? 'yellow.50' : undefined}
                            onClick={() => toggleView('extended')}
                        >Prosjektplan</Button>
                        <Button
                            visibility={"visible"}
                            borderColor="gray.500"
                            _hover={{ borderColor: 'gray.400' }}
                            size="xs"
                            bg={view === 'byggplan' ? 'yellow.50' : undefined}
                            onClick={() => toggleView('byggplan')}
                        >Bygg</Button>

                    </ButtonGroup>
                </Stack>

                <Stack
                    direction="row"
                    alignItems='center'
                    spacing="0">
                    <FormLabel mb="0" fontSize="xs">
                        Ukeshorisont:
                    </FormLabel>
                    <ButtonGroup
                        size='sm'
                        isAttached
                        variant='outline'
                        pr="2"
                    >
                        <Button
                            borderColor="gray.500"
                            _hover={{ borderColor: 'gray.400' }}
                            size="xs"
                            bg={lookAhead === 0 ? 'yellow.50' : undefined}
                            onClick={() => dispatch(setLookAhead(0))}
                        >Ingen</Button>
                        <Button
                            borderColor="gray.500"
                            _hover={{ borderColor: 'gray.400' }}
                            size="xs"
                            bg={lookAhead === 4 ? 'yellow.50' : undefined}
                            onClick={() => dispatch(setLookAhead(4))}
                        >4 uker</Button>
                        <Button
                            borderColor="gray.500"
                            _hover={{ borderColor: 'gray.400' }}
                            size="xs"
                            bg={lookAhead === 6 ? 'yellow.50' : undefined}
                            onClick={() => dispatch(setLookAhead(6))}
                        >6 uker</Button>

                    </ButtonGroup>
                </Stack>

                <Stack
                    direction="row"
                    alignItems='center'
                    spacing="0">
                    <FormLabel mb="0" fontSize="xs">
                        Tid:
                    </FormLabel>
                    <ButtonGroup
                        size='sm'
                        isAttached
                        variant='outline'
                        pr="2"
                    >
                        <Button
                            borderColor="gray.500"
                            _hover={{ borderColor: 'gray.400' }}
                            size="xs"
                            bg={tid === 'baseline' ? 'yellow.50' : undefined}
                            onClick={() => dispatch(setTid('baseline'))}
                        >Baseline</Button>
                        <Button
                            borderColor="gray.500"
                            _hover={{ borderColor: 'gray.400' }}
                            size="xs"
                            bg={tid === 'operativ' ? 'yellow.50' : undefined}
                            onClick={() => dispatch(setTid('operativ'))}
                        >Operativ</Button>
                    </ButtonGroup>
                </Stack>

                <Stack>
                    <FormControl
                        display='flex'
                        alignItems='center'
                        flexGrow={0}
                        onChange={() => showDeps === true ? dispatch(setShowDeps(false)) : dispatch(setShowDeps(true))}
                    >
                        <Switch
                            colorScheme="purple"
                            isChecked={showDeps === true}
                            isDisabled={tid === 'baseline'}
                            mr="1"
                        />

                        <FormLabel mb="0" fontSize="xs">
                            Vis IKT-relasjoner
                        </FormLabel>
                    </FormControl>
                </Stack>


                <Stack>
                    <FormControl
                        display='flex'
                        alignItems='center'
                        flexGrow={0}
                        onChange={() => showProg === true ? dispatch(setShowProgress(false)) : dispatch(setShowProgress(true))}
                    >
                        <Switch
                            colorScheme="green"
                            isChecked={showProg === true}
                            mr="1"
                        />

                        <FormLabel mb="0" fontSize="xs">
                            Vis fremdrift
                        </FormLabel>
                    </FormControl>
                </Stack>

                <Stack>
                    <FormControl
                        display='flex'
                        alignItems='center'
                        flexGrow={0}
                        onChange={() => showDates === true ? dispatch(setShowDates(false)) : dispatch(setShowDates(true))}
                    >
                        <Switch
                            colorScheme="gray"
                            isChecked={showDates === true}
                            mr="1"
                        />

                        <FormLabel mb="0" fontSize="xs">
                            Vis datoer
                        </FormLabel>
                    </FormControl>
                </Stack>

                <Stack>
                    <FormControl
                        display='flex'
                        alignItems='center'
                        flexGrow={0}
                        onChange={() => showWeeks === true ? dispatch(setShowWeeks(false)) : dispatch(setShowWeeks(true))}
                    >
                        <Switch
                            colorScheme="gray"
                            isChecked={showWeeks === true}
                            mr="1"
                        />

                        <FormLabel mb="0" fontSize="xs">
                            Vis ukelinjer
                        </FormLabel>
                    </FormControl>
                </Stack>

            </Stack>


            {!showU7buttons ? null : (
                <Stack
                    direction="row"
                >

                    <ButtonGroup
                        size='sm'
                        isAttached
                        variant='outline'
                    >
                        {/* <Button
                            borderColor="gray.500"
                            _hover={{ borderColor: 'gray.400' }}
                            size="xs"
                            onClick={makeAllVisible}
                            bg={visible.length === lvl1.length ? 'yellow.50' : undefined}

                        >Alle</Button>

                        <Button
                            borderColor="gray.500"
                            _hover={{ borderColor: 'gray.400' }}
                            size="xs"
                            onClick={makeAllHidden}
                            bg={visible.length === 0 ? 'yellow.50' : undefined}

                        >Ingen</Button> */}

                        {lvl1.map(lvl1Id => {
                            return (
                                <Button
                                    key={lvl1Id}

                                    borderColor="gray.500"
                                    _hover={{ borderColor: 'gray.400' }}
                                    size="xs"
                                    bg={visible.includes(lvl1Id) ? 'yellow.50' : undefined}

                                    // size="xs"
                                    // variant="link"
                                    onClick={() => toggleSingleVisible(lvl1Id)}
                                // isActive={visible.includes(lvl1Id)}
                                >{lvl1Id}</Button>
                            )
                        })}


                    </ButtonGroup>

                    <Stack
                        direction="row">

                        <Button
                            size="xs"
                            variant="ghost"
                            disabled={visible.length !== 1}
                            onClick={prevVisible}
                        >&#9664;</Button>

                        <Button
                            size="xs"
                            variant="ghost"
                            disabled={visible.length !== 1}
                            onClick={nextVisible}
                        >&#9654;</Button>
                    </Stack>

                </Stack>
            )}

            {!showByggButtons ? null : (
                <Stack
                    direction="row"
                >

                    <ButtonGroup
                        size='sm'
                        isAttached
                        variant='outline'
                    >
                        {byggstruktur.map(({ code, id, color }) => {
                            if (code !== "X") {
                                return (
                                    <Button
                                        key={id}
                                        borderColor="gray.500"
                                        _hover={{ borderColor: 'gray.400' }}
                                        size="xs"
                                        bg={viewBygg === id ? 'yellow.50' : undefined}

                                        // size="xs"
                                        // variant="link"
                                        onClick={() => toggleViewBygg(id)}
                                        color={color}
                                        fontWeight={800}
                                    >{code}</Button>
                                )
                            }

                        })}

                    </ButtonGroup>
                </Stack>
            )}






        </Stack >
    )

}