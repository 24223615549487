import Graph from "graphology";
import { hasCycle } from "graphology-dag";
import { DateTime } from "luxon";
import { BaseElement, BaseElementDict, Dependency, MasterIndex, ProgressState, RawLineDict, SpecLineDict, byggstruktur, defaultMasterIndex, elementClass, lvl1, lvl2, u7c } from "../types.d";
import getInterpolatedLinearFactorBetweenDates from "./getInterpolatedLinearFactorBetweenDates";
import getLastCutoff from "./getLastCutoff";
import getClss from "./helpers/getClss";
import getLabel from "./helpers/getLabel";
import getLane from "./helpers/getLane";
import { makeIntermediaryIndexForNode } from "./helpers/graphHelpers";

/**
 * 
      '331505': {
        seq: '19091',
        id: '331505',
        desc: 'Plan for etablering av prosjektdrift utarbeides',
        start: '2023-12-14T23:00:00.000Z',
        end: '2024-02-22T23:00:00.000Z',
        Varighet: 45,
        Entr: 'SPHF',
        Planlagt: 1,
        Faktisk: 1,
        Timer: 0.1,
        'BL Start': '2023-12-14T23:00:00.000Z',
        'BL Slutt': '2024-02-22T23:00:00.000Z',
        'BL Planlagt': 1,
        'Entr kommentar': 'Overordnet dokument utgitt. HSØ-8250-F-PL-0002',
        Statusindikator: 'grå',
        WBS: '4.1.1',
        'Nivå 1': '33 - Sykehuspartner',
        'Nivå 2': '15 - Hovedmilepæler generelt',
        'Fløy': '000 - Generelt - bygguavhengig',
        'Samlet Plan avhengigheter': 'x',
        'Samlet Plan Forum': '1'
      },

 */

export default function processUpdateElementsAndIndexes(rawData: RawLineDict, rawOrder: string[], specDict: SpecLineDict, dependencies: Dependency[], startRaw: [number, number, number]): {
    elements: BaseElementDict,
    masterIndex: MasterIndex,
} {

    const today = DateTime.now()
    const lastCutoff = getLastCutoff(today)

    let elements: BaseElementDict = {}
    let masterIndex: MasterIndex = structuredClone(defaultMasterIndex)


    // Initialize static indexes
    elementClass.forEach(elClass => masterIndex.clss[elClass] = [])
    byggstruktur.forEach(byggStr => {
        masterIndex.bygg[byggStr.id] = []
        masterIndex.byggRelated[byggStr.id] = []
    })
    lvl1.forEach(lvl1Id => {
        masterIndex.children[lvl1Id] = []
        lvl2.forEach(lvl2Id => {
            const lvl1Lvl2Id = `${lvl1Id}-${lvl2Id}`
            masterIndex.children[lvl1Lvl2Id] = []
        })
    })

    interface NodeAttributes { }
    interface EdgeAttributes { }
    const graph = new Graph<NodeAttributes, EdgeAttributes>({
        multi: false,
        allowSelfLoops: false,
        type: 'directed'
    })

    const timelineStart = DateTime.local(...startRaw)

    rawOrder.forEach((id, i) => {
        const line = rawData[id]

        // Exclude activities and milestones ending before start of timeline, for unmapped items.
        const start_op = line.start instanceof Date ? DateTime.fromJSDate(line.start) : DateTime.fromISO(line.start)
        const end_op = line.end instanceof Date ? DateTime.fromJSDate(line.end) : DateTime.fromISO(line.end)
        let start_bl = line.start_bl instanceof Date ? DateTime.fromJSDate(line.start_bl) : DateTime.fromISO(line.start_bl)
        let end_bl = line.end_bl instanceof Date ? DateTime.fromJSDate(line.end_bl) : DateTime.fromISO(line.end_bl)

        // Fix bug i underlag hvor end ofte står før start på baseline:
        end_bl = end_bl < start_bl ? start_bl : end_bl

        // Fix bug i underlag hvor start og end er helt off - sett da til operativ:2025, 1, 1
        const outOfBounds = DateTime.local(2020, 1, 1)
        if (end_bl < outOfBounds) {
            end_bl = end_op
            start_bl = start_op
        }

        if ((timelineStart < end_op || timelineStart < end_bl) && !line.desc.includes('CANCELED')) {

            const clss = getClss(line)
            const lane = getLane(line, clss)
            const label = getLabel(line, clss)

            const actual = line['actual'] as number
            const estimated_op = getInterpolatedLinearFactorBetweenDates(start_op, end_op, lastCutoff)
            const estimated_bl = getInterpolatedLinearFactorBetweenDates(start_bl, end_bl, lastCutoff)

            const progress_op: ProgressState = actual === 1 ? 'done'
                : estimated_op === 1 ? 'late'
                    : estimated_op === 0 ? 'ahead'
                        : estimated_op > actual ? 'lagging' : 'ahead'

            const progress_bl: ProgressState = actual === 1 ? 'done'
                : estimated_bl === 1 ? 'late'
                    : estimated_bl === 0 ? 'ahead'
                        : estimated_bl > actual ? 'lagging' : 'ahead'

            elements[id] = {
                id: id,
                desc: line.desc,
                start: start_op.toISO(),
                end: end_op.toISO()!,
                progress: progress_op,
                estimated: estimated_op,
                actual: actual,
                bygg: line.bygg,
                clss: clss,
                // @ts-ignore
                lvl1: line.lvl1 || '',
                // @ts-ignore
                lvl2: line.lvl2 ? `L${line.lvl2!.toString().padStart(2, '0')}` : "",
                lane: lane,
                alt: label,
                u: line.u,

                start_op: start_op.toISO(),
                end_op: end_op.toISO()!,
                progress_op: progress_op,
                estimated_op: estimated_op,

                start_bl: start_bl.toISO(),
                end_bl: end_bl.toISO()!,
                progress_bl: progress_bl,
                estimated_bl: estimated_bl,
            } as BaseElement


        }
    })



    dependencies.forEach(({ from, to, type }) => {
        if (elements[from] && elements[to]) {
            graph.updateNode(from)
            graph.updateNode(to)
            graph.updateEdge(from, to)
            masterIndex.linkTypes[`${from}-${to}`] = type
        }
    })

    if (hasCycle(graph)) {
        alert('CirC!')
    }


    // POPULATE INDEXES
    Object.entries(elements).forEach(([id, el]) => {

        masterIndex.intermediary[id] = []

        if (el.bygg && el.bygg !== "000") {
            masterIndex.filters.byggspesifikke.push(id)
            // masterIndex.byggActivities[el.bygg].push(id)
        }

        // Index by class
        masterIndex.clss[el.clss].push(id)

        try {
            masterIndex.bygg[el.bygg] ??= []
            masterIndex.bygg[el.bygg].push(id)
        }
        catch { console.error('masterIndex.bygg[el.bygg].push(id) failed for', el) }


        if (el.eie && el.clss !== 'eierskifte') {
            masterIndex.eierskifte[el.eie].push(id)
        }

        masterIndex.inbounds[el.id] = []
        if (graph.hasNode(id)) {
            graph.forEachInNeighbor(id, (id2) => {

                if (elements[id2] && (elements[id2].u === 7 || elements[id].u === 7)) { // Only index relations where at least one is U7

                    // If target of edge is IST, register as inbound
                    masterIndex.inbounds[el.id].push(id2)
                    if (el.clss === 'ist') {
                        if (!masterIndex.filters.istPlanRelated.includes(id2)) {
                            masterIndex.filters.istPlanRelated.push(id2)
                        }
                        if (!masterIndex.filters.satPlan.includes(id2)) {
                            masterIndex.filters.satPlan.push(id2)
                        }
                    }

                }

            })
        }


        if (el.clss === 'sat-done') {
            if (!masterIndex.filters.satPlan.includes(id)) {
                masterIndex.filters.satPlan.push(id)
            }
        }

        if (el.lvl1) {

            try {
                masterIndex.children[el.lvl1] ??= []
                masterIndex.children[el.lvl1].push(id)
            }
            catch { console.error('masterIndex.children[el.lvl1].push(id) failed for', el) }

            if (el.lvl2 && el.clss !== 'leveranse') {
                const index = `${el.lvl1}-${el.lvl2}`
                masterIndex.children[index] ??= []
                masterIndex.children[index].push(id)
            }

        }

        if (!!el.u) {
            if (graph.hasNode(id)) {
                masterIndex.intermediary[id] = makeIntermediaryIndexForNode(id, elements, graph)
            }
        }

        // if (el.lvl1) {
        //     masterIndex.children[el.lvl1].push(id)
        //     if (el.lvl2 && el.clss !== 'leveranse') {
        //         const lvl1Lvl2Id = `${el.lvl1}-${el.lvl2}`
        //         masterIndex.children[lvl1Lvl2Id].push(id)
        //     }
        // }
    })


    // Lag byggRelated-index
    // For hvert bygg
    // Finn alle elementer
    // For hvert element, finn alle intermediary (Som ikke er i bygg)
    // For hvert element, finn alle outbound som er IKT  (Som ikke er i bygg)
    byggstruktur.forEach((bygg) => {
        let byggIndex: string[] = []

        masterIndex.bygg[bygg.id].forEach(elId => {

            // Add intermediaries to IST for items in bygg
            masterIndex.intermediary[elId].forEach(interId => {
                if (byggIndex.indexOf(interId) === -1
                    && elements[interId].clss !== 'ist'
                    && masterIndex.bygg[bygg.id].indexOf(interId) === -1) byggIndex.push(interId)
            })

            // Add IKT outbound relations
            if (graph.hasNode(elId)) {
                graph.forEachOutNeighbor(elId, (id2) => {
                    if (elements[id2] && elements[id2].u === 7
                        && byggIndex.indexOf(id2) === -1
                        && masterIndex.bygg[bygg.id].indexOf(id2) === -1
                        && elements[id2].clss !== 'ist') {
                        byggIndex.push(id2)
                    }
                })
            }
        })

        masterIndex.byggRelated[bygg.id] = byggIndex
    })


    byggstruktur.forEach(({ id }) => {
        if (!(id in elements)) {
            elements[id] = {
                id: id,
                desc: '',
                start: '',
                end: '',
                progress: 'unknown',
                actual: 0,
                estimated: 0,
                bygg: "000",
                clss: 'bygg',
                u: 0,
                lvl1: "",
                lvl2: undefined,

                start_op: '',
                end_op: '',
                progress_op: 'unknown',
                estimated_op: 0,
                start_bl: '',
                end_bl: '',
                progress_bl: 'unknown',
                estimated_bl: 0,

            }
        }
    })

    u7c.forEach((id) => {
        if (!(id in elements)) {
            elements[id] = {
                id: id,
                desc: '',
                start: '',
                end: '',
                progress: 'unknown',
                actual: 0,
                estimated: 0,
                bygg: "000",
                clss: 'u7c',
                u: 0,
                lvl1: "",
                lvl2: undefined,

                start_op: '',
                end_op: '',
                progress_op: 'unknown',
                estimated_op: 0,
                start_bl: '',
                end_bl: '',
                progress_bl: 'unknown',
                estimated_bl: 0,
            }
        }
    })





    return {
        elements,
        masterIndex,
    }

}