import { ElementClass, RawLine } from "../../types.d";

export default function getClss(line: RawLine): ElementClass {


    if (line.isLvl2) {
        return "leveranse"
    }

    else if (
        line.desc.startsWith("Integrert test (IST) IKT")
        // line.desc.startsWith("IST (IKT)")
    ) {
        return "ist"
    }

    else if (line.desc.startsWith("VIT-")) {
        return "vit"
    }
    else if (line.id.startsWith("NSD-PM") && line.desc.includes("KIB")) {
        return "kib"
    }
    else if (line.id.startsWith("NSD-PM") && line.desc.includes("Eierskifte") && !line.desc.includes("SAT")) {
        return "eierskifte"
    }
    else if (line.desc.includes("SAT") && line.lvl1) {
        const desc = line.desc.toLowerCase()
        if (desc.includes("gjennomført")) {
            return "sat-done"
        }
        else if (desc.includes("plan") && desc.includes("godkjent")) {
            return "sat-plan"
        }
        else {
            return "generic"
        }
    }
    else if (line.desc.includes("ST/SIT") && line.lvl1) {
        const desc = line.desc.toLowerCase()
        if (desc.includes("gjennomført")) {
            return "st-sit-done"
        }
        else if (desc.includes("plan") && desc.includes("godkjent")) {
            return "st-sit-plan"
        }
        else {
            return "generic"
        }
    }
    else {
        return "generic"
    }

}