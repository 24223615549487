import { DateTime } from "luxon";
import { BaseElementDict, XIndex } from "../types.d";
import getLastCutoff from "./getLastCutoff";


export default function processXData(elements: BaseElementDict, timeScale: d3.ScaleTime<number, number, never>): XIndex {

    const ret: XIndex = {}

    Object.keys(elements).forEach(id => {
        const elem = elements[id]
        if (elem) {
            ret[id] = {
                start: timeScale(DateTime.fromISO(elem.start)),
                end: timeScale(DateTime.fromISO(elem.end)),
                start_op: timeScale(DateTime.fromISO(elem.start_op)),
                end_op: timeScale(DateTime.fromISO(elem.end_op)),
                start_bl: timeScale(DateTime.fromISO(elem.start_bl)),
                end_bl: timeScale(DateTime.fromISO(elem.end_bl)),
            }
        }
    })

    const now = DateTime.now()
    const lastCutoff = getLastCutoff(now)

    ret['__today'] = {
        start: timeScale(now),
        end: timeScale(now),
        start_op: timeScale(now),
        end_op: timeScale(now),
        start_bl: timeScale(now),
        end_bl: timeScale(now),
    }

    ret['__today6w'] = {
        start: timeScale(now),
        end: timeScale(now.plus({ weeks: 6 })),
        start_op: timeScale(now),
        end_op: timeScale(now.plus({ weeks: 6 })),
        start_bl: timeScale(now),
        end_bl: timeScale(now.plus({ weeks: 6 })),
    }

    ret['__today4w'] = {
        start: timeScale(now),
        end: timeScale(now.plus({ weeks: 4 })),
        start_op: timeScale(now),
        end_op: timeScale(now.plus({ weeks: 4 })),
        start_bl: timeScale(now),
        end_bl: timeScale(now.plus({ weeks: 4 })),
    }

    ret['__lastCutoff'] = {
        start: timeScale(lastCutoff),
        end: timeScale(lastCutoff),
        start_op: timeScale(lastCutoff),
        end_op: timeScale(lastCutoff),
        start_bl: timeScale(lastCutoff),
        end_bl: timeScale(lastCutoff),
    }

    ret['__påske'] = {
        start: timeScale(DateTime.local(2025, 4, 14)),
        end: timeScale(DateTime.local(2025, 4, 21)),
        start_op: timeScale(DateTime.local(2025, 4, 14)),
        end_op: timeScale(DateTime.local(2025, 4, 21)),
        start_bl: timeScale(DateTime.local(2025, 4, 14)),
        end_bl: timeScale(DateTime.local(2025, 4, 21)),
    }

    ret['__jul'] = {
        start: timeScale(DateTime.local(2024, 12, 20)),
        end: timeScale(DateTime.local(2025, 1, 5)),
        start_op: timeScale(DateTime.local(2024, 12, 20)),
        end_op: timeScale(DateTime.local(2025, 1, 5)),
        start_bl: timeScale(DateTime.local(2024, 12, 20)),
        end_bl: timeScale(DateTime.local(2025, 1, 5)),
    }

    ret['__sommerferie'] = {
        start: timeScale(DateTime.local(2025, 7, 5)),
        end: timeScale(DateTime.local(2025, 7, 27)),
        start_op: timeScale(DateTime.local(2025, 7, 5)),
        end_op: timeScale(DateTime.local(2025, 7, 27)),
        start_bl: timeScale(DateTime.local(2025, 7, 5)),
        end_bl: timeScale(DateTime.local(2025, 7, 27)),
    }
    

    return ret
}