import React from "react";
import { useSelector } from "react-redux";
import useLegendTimelineData from "../../hooks/useLegendTimelineData";
import { selectMasterIndex } from "../../slice";
import VerticalBreakIndicator from "./VerticalBreakIndicator";
import VerticalIndicator from "./VerticalIndicator";
import VerticalTodayIndicator from "./VerticalTodayIndicator";
import VerticalWeekIndicator from "./VerticalWeekIndicator";


export default function VerticalIndicators() {

    const masterIndex = useSelector(selectMasterIndex)
    const { weekSegments } = useLegendTimelineData()


    const elements = [
        ...(masterIndex.clss['kib'] || []),
        ...(masterIndex.clss['eierskifte'] || []),
    ]

    return (
        <React.Fragment>
            {weekSegments.map((segment, i) => (
                <VerticalWeekIndicator segment={segment} index={i} key={segment.label} />
            ))}
            <VerticalBreakIndicator id={'__påske'} />
            <VerticalBreakIndicator id={'__jul'} />
            <VerticalBreakIndicator id={'__sommerferie'} />
            {elements.map(id => (
                <VerticalIndicator
                    key={id}
                    id={id} />
            ))}
            <VerticalTodayIndicator />

        </React.Fragment>
    )

}