import { DateTime } from "luxon";
import { RawLineDict } from "../types.d";
import postProcessRawData from "./postProcessRawData";


export default function processRawData(input: any[]): { data: RawLineDict, order: any[] } {


    let order: any = []
    let lineDict: RawLineDict = {}

    input.forEach((row: any) => {
        let ret: any = {}

        // Clean up date objects
        Object.entries(row).forEach(([key, val]) => {
            ret[key] = val instanceof Date ? DateTime.fromJSDate(val).toISO()! : val
        })

        ret['bygg'] = (ret['floy'] || "000").substring(0, 3)

        if (!ret.desc.includes("CANCELLED")) {
            lineDict[row.seq] = ret
            order.push(row.seq)
        }

    })



    return {
        data: postProcessRawData(lineDict, order),
        order
    }

}